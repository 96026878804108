/* eslint-disable */
import {gsap} from "gsap";
import animationKill from '../../resources/js/helpers/animation-kill';
import removeInlineStyles from '../../resources/js/helpers/remove-inline-styles';
import removePinSpacer from '../../resources/js/helpers/remove-pin-spacer';

window.addEventListener('load', () => {
  homeHeroBlock.init();
});

const homeHeroBlock = {
  init: function () {
    const sections = document.querySelectorAll('[data-home-hero-block-js]');

    if (!sections.length) return;

    sections.forEach((section) => {
      this.innerPinAnim(section);
      this.videoWrapAnim(section);

      let windowWidth = window.innerWidth;
      window.addEventListener('optimizedResize', () => {
        if (window.innerWidth !== windowWidth) {
          this.videoWrapAnim(section);
          this.innerPinAnim(section);
          windowWidth = window.innerWidth;
        }
      });
    })

  },

  innerPinAnim: function (section) {
    let heroInner = section.querySelector('.home-hero__inner');

    /*todo: window.innerWidth >= 1024 or landscape mode ?*/
    if (heroInner) {
      const pinAnimation = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: 'top top',
          end: 'bottom bottom',
          scrub: true,
          pin: heroInner,
        }
      });

      let windowWidth = window.innerWidth;
      window.addEventListener('optimizedResize', () => {
        if (window.innerWidth !== windowWidth) {
          animationKill(pinAnimation);
          removeInlineStyles(heroInner);
          removePinSpacer(section);
          windowWidth = window.innerWidth;
        }
      });
    }
  },

  videoWrapAnim: function (section) {
    let videoWrap = section.querySelector('.home-hero__video-wrap');
    let videoEl = section.querySelector('[data-video-el-js]');
    let videoAnimation = false;
    let videoAnimation2 = false;
    let videoAnimation3 = false;
    let windowHeight = window.innerHeight - 1;

    /*todo: window.innerWidth >= 1024 or landscape mode ?*/
    if (videoWrap) {
      let videoWrapWidth = videoWrap.clientWidth;
      videoAnimation = gsap.fromTo(
        videoWrap, {
          y: '100vh',
        }, {
          y: '-100vh',
          ease: "none",
          scrollTrigger: {
            trigger: section,
            start: `top-=1px top`,
            end: `bottom bottom`,
            scrub: true,
            onUpdate: (self) => {
              // console.log(self.progress);
            }
          }
        });

      videoAnimation2 = gsap.fromTo(
        videoWrap, {
          width: `${videoWrapWidth}px`,
          borderTopLeftRadius: `${videoWrapWidth / 2}px`,
          borderTopRightRadius: `${videoWrapWidth / 2}px`,
        }, {
          width: '100vw',
          borderTopLeftRadius: '50vw',
          borderTopRightRadius: '50vw',
          ease: "none",
          scrollTrigger: {
            trigger: section,
            start: 'top+=10% top',
            end: 'top+=60% top',
            scrub: true,
          }
        });
    }

    if (videoEl) {
      videoAnimation3 = gsap.fromTo(
        videoEl, {
          y: '0',
        }, {
          y: '100vh',
          ease: "none",
          scrollTrigger: {
            trigger: section,
            start: `top+=50% top+=50%`,
            end: 'bottom bottom',
            scrub: true,
          }
        });
    }

    videoWrap.classList.add('is-init');
    let windowWidth = window.innerWidth;
    window.addEventListener('optimizedResize', () => {
      if (window.innerWidth !== windowWidth) {
        animationKill(videoAnimation);
        animationKill(videoAnimation2);
        animationKill(videoAnimation3);
        removeInlineStyles(videoWrap);

        videoWrap.classList.remove('is-init');
        windowWidth = window.innerWidth;
      }
    });
  },

}

export default homeHeroBlock;
