export default (section) => {
  if(section){
    const pinSpacer = section.querySelector('.pin-spacer');
    if (pinSpacer) {
      const parent = pinSpacer.parentNode;
      while (pinSpacer.firstChild) {
        parent.insertBefore(pinSpacer.firstChild, pinSpacer);
      }
      parent.removeChild(pinSpacer);
    }
  }
}
